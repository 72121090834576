@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
$snake: 12px solid rgb(17, 63, 137);
#KRASNOYARSK, #PRI, #SVE, #VLA, .Moscow, #TUL, #VGG, #KDA, #STA /*, #Crimea*/ {
  &:hover {
    cursor: crosshair;
  }
  position: relative;
}
.map {
  position: relative;
  z-index: 0;
  .case-name {
    position: absolute;
    top: -10%;
    left: 0%;
    background: linear-gradient(90deg, #08BCD5, #13F025);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-weight: bold;
    font-size: 41px;
    line-height: 50px;
    /* or 122% */
    width: min-content;
    text-transform: uppercase;
  }
  .map-text {
    display: none;
    color: black;
    z-index: 20;
    font-size:30px;
    fill: red;
  }
}
//#Crimea {
//  transform: translateY(-245px) translateX(-20px) scale(1.5);
//  transition: 2s;
//  &:hover {
//    filter: url(#shadow);
//    cursor: crosshair;
//    transform: translateY(-455px) translateX(-20px) scale(2.0);
//  }
//}
#KRASNODAR {
  transition: 2s;
  &:hover {
    filter: url(#shadow);
    cursor: crosshair;
    transform: translateY(-480px) translateX(-50px) scale(2.0);
  }
}
#KRASNOYARSK {
  transition: 2s;
  &:hover {
    filter: url(#shadow);
    cursor: crosshair;
    transform: translateY(-150px) translateX(-180px) scale(1.3);
  }
}
#STA {
  transition: 2s;
  &:hover {
    filter: url(#shadow);
    cursor: crosshair;
    transform: translateY(-500px) translateX(-80px) scale(2.0);
  }
}
#PRI {
  transition: 2s;
  &:hover {
    filter: url(#shadow);
    cursor: crosshair;
    transform: translateY(-300px) translateX(-470px) scale(1.5);
  }
}
#VGG {
  transition: 2s;
  &:hover {
    filter: url(#shadow);
    cursor: crosshair;
    transform: translateY(-460px) translateX(-120px) scale(2.0);
  }
}
#TUL {
  transition: 2s;
  &:hover {
    filter: url(#shadow);
    cursor: crosshair;
    transform: translateY(-400px) translateX(-160px) scale(2.0);
  }
}
#VLA {
  transition: 2s;
  &:hover {
    filter: url(#shadow);
    cursor: crosshair;
    transform: translateY(-360px) translateX(-155px) scale(2.0);
  }
}
#SVE {
  transition: 2s;
  &:hover {
    filter: url(#shadow);
    cursor: crosshair;
    transform: translateY(-180px) translateX(-125px) scale(1.4);
  }
}
.Moscow {
  transition: 2s;
  &:hover {
    filter: url(#shadow);
    cursor: crosshair;
    transform: translateY(-370px) translateX(-150px) scale(2.0);
  }
}
body {
  z-index: 0;
  background: #001134;
  padding: 0;
  margin: 0;
  min-width: 320px;
  min-height: 100vh;
  color: #f5f5f5;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}
p {
  font-size: 18px;
}
header .fa-linkedin {
  transform: translateY(2px);
}
.navbar {
  padding: 0;
}
.basket {
  .basket-title {
    text-transform: none;
    width: max-content;
    max-width: 100%;
  }
  .privacy {
    margin-top: 30px;
    .text-wrap {
      text-decoration: underline;
    }
  }
  textarea.input_contacts {
    height: 100px;
  }
  .input_contacts {
    &::placeholder {
      color: rgb(184, 184, 184);
    }
    border-radius: 0;
    border-width: 1px;
    border-image: linear-gradient(90deg, rgba(7,186,219, .2), rgba(19,242,28, .2));
    border-image-slice: 0 1 1 0;
    width: 368px;
    height: 42px;
    margin-bottom: 25px;
    margin-top: 0;

    line-height: 26px;

    color: #FFFFFF;
  }
}
.modal {
  .modal-dialog {
    max-width: 1034px;
    min-width: 1034px;
    max-height: 620px;
    background: #001134;
    margin-top: 116px;
    background: #001134;
    box-shadow: 0px 0px 10px #000000;
    .privacy {
      margin-bottom: 0;
    }
    .react-tel-input {
      margin-top: 0;
    }
    .modal-content {
      margin: 0;
      height: 620px;
      width: 100%;
      padding: 33px 69px;
      background-image: url("/images/modal.png");
      textarea.input_contacts {
        height: 100px;
      }
      .input_contacts {
        &::placeholder {
          color: rgb(184, 184, 184);
        }
        border-radius: 0;
        border-width: 1px;
        border-image: linear-gradient(90deg, rgba(7,186,219, .2), rgba(19,242,28, .2));
        border-image-slice: 0 1 1 0;
        width: 368px;
        height: 42px;
        margin-bottom: 25px;
        margin-top: 0;

        line-height: 26px;

        color: #FFFFFF;
      }
    }

  }

}

.main-title {
  width: 992px;
  height: 108px;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.main-title-brand {
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.mouse {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

}

.main-bg {
  background-image: url("/images/bg1.png");
  background-position: 50% 0%;
  background-size: cover;
}
.contact_bg {
  background-image: url("/images/servicesPage/подложка.jpg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.serv-bg {

  //background-color: #081b39;
  -webkit-background-size: 100%;
  -o-background-size: 100%;
  background-image: url("/images/servicesPage/подложка.jpg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  .footer{
    padding-top: 100px;
  }

  .serv-bg_title-text {
    font-size: 16px;
    line-height: 26px;
    color: #D9DBE0;
    margin-bottom: 30px;
  }
  .setv-bg_case-img {
    transform: translateY(-20px);
    height: 270px;
    width: 320px;
  }
}

.main-keys {
  background-image: url("/images/cases/подложка.jpg");
}


a {
  font-size: 17px;

  color: rgb(255, 255, 255);
  line-height: 34px;
  z-index: 35;

  .fa {
    color: rgb(68, 197, 64);
    font-size: 30px;
  }
}

.page-link {
  border: none;
  color: white;



  font-weight: 600;
  line-height: 1.5;
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  outline: none;

  &.active {
    background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:focus {
    box-shadow: none;
  }
}

.li-before {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: -25px;
    top: 1px;
    width: 20px;
    height: 20px;
    background: url("/images/roadmap/pols.png") no-repeat;
  }
}

b {
  margin-bottom: 20px;
}

.header {
  .header-one {
    .btn-link:hover {
      color: #44c540;
    }
    height: 116px;

    .row {
      height: 100%;
    }
  }

  a {
    color: white;
  }

  .social {
    span {
      margin-right: 20px;
    }

    a {
      margin-right: 18px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .lang {
    width: 80px;
  }

  .header_small {
    background-color: #001134;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .dropdown-men {
    padding: 25px 25px;
    z-index: 100;
    background-color: #001134;
    overflow-y: hidden;
    position: absolute;
    width: 100vw;
    height: 120vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  .header_small {
    padding: 0;
  }
    .lang {
      width: 40px;
    }
    .nav-item {
      &:first-child {
        margin-top: 25px;
      }
    }
    .nav-link {
      width: max-content;
      font-weight: 600;

      line-height: 22px;
      margin-bottom: 22px;
      &.active {
        font-weight: 700;
        background: linear-gradient(90deg, #08BCD5, #13F025);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.header .bg-grad {
  .nav-item:first-child .nav-link {
    padding-left: 0;
  }

  .nav-item:last-child .btn {
    padding-right: 0;
  }

  height: 57px;
  background: linear-gradient(90deg, #175f94, #267e66);

  a:hover {
    color: #44c540;
  }
}

.btn {
  &:focus, &:active {
    box-shadow: none;
  }
}

.btn-color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 232px;
  height: 56px;
  color: white;
  background: linear-gradient(90deg, #0C7954 0%, #21A220 100%);
  margin-bottom: 16px;

  &:hover {
    text-decoration: none;
    color: white;
  }

  img {
    margin-left: 18px;
  }
}

.btn-color_second {
  padding: 15px 30px;
  width: max-content;
  background: linear-gradient(90deg, rgb(12, 121, 84) 0%, rgb(33, 163, 33) 100%);
  background-image: -moz-linear-gradient(90deg, rgb(12, 121, 84) 0%, rgb(33, 163, 33) 100%, rgb(255, 255, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(12, 121, 84) 0%, rgb(33, 163, 33) 100%, rgb(255, 255, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(12, 121, 84) 0%, rgb(33, 163, 33) 100%, rgb(255, 255, 255) 100%);
  text-align: center;


  color: rgb(242, 242, 242);
  line-height: 1.421;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 18px;
  }

  border: none;
  outline: none;

  &:hover {
    text-decoration: none;
    color: rgb(242, 242, 242) !important;
    cursor: pointer;
  }
}

.about {
  .btn_about {
    margin-bottom: 35px;
    padding: 10px 20px;
    background-color: transparent;
    border: none;


    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.389;

    &.active {
      background: linear-gradient(90deg, rgb(29, 116, 111) 0%, rgb(73, 163, 68) 100%, rgb(255, 255, 255) 100%);
      background-image: -moz-linear-gradient(90deg, rgb(29, 116, 111) 0%, rgb(73, 163, 68) 100%, rgb(255, 255, 255) 100%);
      background-image: -webkit-linear-gradient(90deg, rgb(29, 116, 111) 0%, rgb(73, 163, 68) 100%, rgb(255, 255, 255) 100%);
      background-image: -ms-linear-gradient(90deg, rgb(29, 116, 111) 0%, rgb(73, 163, 68) 100%, rgb(255, 255, 255) 100%);
    }

    &.active_small {
      background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .btn_about_small {
    &:first-child {
      padding-left: 0;
      padding-right: 0;
    }

    font-weight: 700;

    line-height: 22px;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    color: #FFFFFF;
    margin-bottom: 20px;

    &.active {
      font-weight: 800;
      background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .about-text {
    margin-bottom: 20px;
    color: #D9DBE0;
    line-height: 1.5;
  }

  .about_small {
    .about-text {
      display: flex;
      align-items: center;

      color: #D9DBE0;
    }
  }

}

.where_use {
  .top-info {
    margin-bottom: 20px;
  }
  .where_use-block {
    margin-top: 65px;
    justify-content: space-between;
    svg {
      margin-bottom: 10px;
    }

    a {
      &:hover {
        color: white;
        text-decoration: none;
      }

      p {
        margin-bottom: 0;
        line-height: 1.5;
      }

      display: flex;
      flex-direction: column;
      padding: 30px 60px 0px 25px;
      height: 190px;
    }

    a:nth-child(1) {
      background-repeat: no-repeat;
      width: 24%;
      background-image: url(/images/where_use/1.png);

      &:hover {
        background-image: url(/images/where_use/10.png);
      }
    }

    a:nth-child(2) {
      background-repeat: no-repeat;
      width: 22%;
      background-image: url(/images/where_use/2.png);

      &:hover {
        background-image: url(/images/where_use/20.png);
      }
    }

    a:nth-child(3) {
      background-repeat: no-repeat;
      width: 27%;
      background-image: url(/images/where_use/3.png);

      &:hover {
        background-image: url(/images/where_use/30.png);
      }
    }

    a:nth-child(4) {
      background-repeat: no-repeat;
      width: 24%;
      background-image: url(/images/where_use/4.png);

      &:hover {
        background-image: url(/images/where_use/40.png);
      }
    }

    a:nth-child(5) {
      background-repeat: no-repeat;
      width: 33%;
      background-image: url(/images/where_use/5.png);

      &:hover {
        background-image: url(/images/where_use/50.png);
      }
    }

    a:nth-child(6) {
      background-repeat: no-repeat;
      width: 66%;
      background-image: url(/images/where_use/6.png);

      &:hover {
        background-image: url(/images/where_use/60.png);
      }
    }

    a:nth-child(7) {
      background-repeat: no-repeat;
      width: 53%;
      background-image: url(/images/where_use/7.png);

      &:hover {
        background-image: url(/images/where_use/70.png);
      }
    }

    a:nth-child(8) {
      background-repeat: no-repeat;
      justify-self: flex-start;
      align-self: flex-start;
      margin-right: auto;
      margin-left: 10px;
      width: 30%;
      background-image: url(/images/where_use/8.png);

      &:hover {
        background-image: url(/images/where_use/80.png);
      }
    }
  }
}

.WhytopCarusel {
  position: relative;
  height: auto;

  .element_whytop {
    display: flex;
    flex-direction: column;
    align-items: center;

    .svg_block {
      height: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .WhytopCarusel_img_small {
        align-self: center;
        width: 60px;
        height: 60px;
        z-index: 1;
      }
    }

    .text_block {
      width: 90%;


      font-size: 16px;
      line-height: 26px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #FFFFFF;

    }
  }

  .next {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 50px;
    transform: translateX(-50%) translateY(-50%);
  }

  .back {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 50px;
    transform: translateX(50%) translateY(-50%)
  }
}

.services {
  .about-services {
    transform: translateY(7.5px);
    margin-left: 50px;
    .img_main {
      margin: auto;
      margin-top: 30px;
      margin-bottom: 30px;
      width: 60%;
    }
    .cur-img {
      margin-top: 30px;
      width: 70%;
    }
  }

  .about-services_small {
    div {
      font-size: 16px;
      line-height: 26px;
      display: flex;
      color: #FFFFFF;
    }
  }

  .services-list {
    transform: translateY(15px);
    .services-active {
      cursor: pointer;
    }
    .services-active img{
      height: 51px;
      width: 50px;
    }
  }

  ul {
    list-style: none;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(0deg, rgba(0, 0, 0, 0), #07badb, #13f21c, rgba(0, 0, 0, 0));
    border-image-slice: 0 1 0 0;
    height: max-content;
    padding-right: 0;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 45px;

      &:first-child {
        transform: translateY(-15px);
        margin-bottom: 30px;
      }

      &:last-child {
        a {
          margin-bottom: 0;
        }

        margin-bottom: 0;
      }

      p {
        margin-left: 30px;
        font-weight: 400;

        line-height: 1.5;
        margin-bottom: 0;
        max-width: 75%;

      }

      &.active {
        position: relative;

        p {
          font-weight: 600;
          background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &:after {
          content: '';
          margin-left: 20px;
          height: 1px;
          flex-grow: 1;
          background: linear-gradient(90deg, rgba(7, 186, 217, 0.1) 30%, rgb(19, 241, 31));
        }

        &:before {
          content: '';
          background-image: url(/images/services/9.png);
          position: absolute;
          right: 0;
          width: 32px;
          height: 32px;
          transform: translateX(16px);
          flex-grow: 1;
        }
      }
    }
  }

  .btn-color {

    padding: 20px 35px;
    border-radius: 0;
    border: none;
    width: max-content;
  }

  .about-services {
    padding: 0;
    .btn-color {
      font-size: 18px;
    }
    h3 {
      width: max-content;
      max-width: 450px;
      color: rgb(255, 255, 255);
      background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.5;
      margin-bottom: 0;
    }

    p {

      color: rgb(255, 255, 255);
      line-height: 1.5;

      &:first-child {
        margin-bottom: 40px;
      }
    }

    .serv-li {
      div {
        img {
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }
      }

      p {

      }
    }
  }

}

.logo {
  img {
    max-width: 90%;
    max-height: 90%;
  }
}

.ServDetailed-slider_img_small {
  min-width: 170px;
  min-height: 170px;
  display: block;
  margin-left: auto;
  margin-top: 25px;
  margin-right: auto
}

.slider-1_block_small {
  position: relative;
  width: auto !important;

  .rec-slider-container {
    margin: 0;
  }

  .about-item {
    margin: 0;
    min-width: 320px;
    width: 320px;
    height: 255px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .about-item-title {
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    left: 55%;
    top: 50%;
    width: 90%;
    transform: translateX(-50%);
  }

  .next {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .back {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 50%;
    transform: translateX(50%) translateY(-50%)
  }
}

.banner-carusel-col {
  padding: 0;
  position: relative;
}

.about-arrow, .banner-arrow, .media-arrow, .map-arrow {
  z-index: 20;
  border: none;
  background-color: transparent;
}

.banner-arrow {
  position: absolute;
  top: 50%;

  &.next {
    right: -30px;
  }

  &.back {
    left: -30px;
  }
}

.media-arrow {
  position: absolute;
  z-index: 2;
  bottom: 60px;

  &:first-child {
    left: 20px;
  }

  &:last-child {
    right: 20px;
  }
}

.inline_arrow {
  position: absolute;
  bottom: -25px;
  transform: translateY(50%) translateX(-50%);
  display: flex;
  left: 50%;
}

.media-element {
  min-height: 420px;

  .media-element-logo {
    min-height: 50px;
  }
}

.about-item {
  width: 400px;
  height: 220px;
  display: flex;
  align-items: flex-end;
  font-size: 13px;
  color: rgb(245, 245, 245);
  text-transform: uppercase;
  line-height: 1.35;
  position: relative;
  margin: 40px 20px;
  background: no-repeat;
}

.about-item-title {
  position: absolute;
  display: flex;
  align-items: center;
  height: 200px;
  font-size: 21px;
  font-weight: 600;
  bottom: -20px;
  left: 33%;
  transform: translateX(-30%) translateY(40px);
  width: 260px;
  min-height: 60px;
}
.basket {
  .bt-green {
    padding: 15px 33px;
    border: none;
    border-radius: 0;

    line-height: 27px;

    background-repeat: no-repeat;
    background-color: #38ab4e;
    background: linear-gradient(45deg, #0c7953 40%, #21a220 77%);
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;

    .vector {
      margin-left: 18px;
      background-repeat: no-repeat;
      background-image: url("/images/servicesPage/Vector.png");
      width: 33px;
      height: 17px;
      &.trans {
        transform: scaleX(-1);
      }
    }
  }
  .basket_each {
    width: 100%;
    transform: scale(1.3);
  }
  .mb-80 {
    margin-bottom: 80px;
  }
  background-size: 100%;
  .basket-page {
    .input_contacts {
      margin-top: 10px;
      margin-bottom: 20px;
      width: 100%;
      background: none;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid rgb(184, 184, 184);

      &::placeholder {
        color: rgb(184, 184, 184);
      }



      color: rgb(255, 255, 255);
      line-height: 1.5;

      &:focus {
        outline: none;
      }
    }
    .container-title h1 {
      margin-top: 53px;
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 25px;
      line-height: 27px;
    }
    .basket-element {
      background-repeat: no-repeat;
      margin-bottom: 20px;
      img {
        width: 181.08px;
        &.grey {
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
        }
      }
      .basket_text {
        padding: 24px;
        padding-right: 0;
      }
      p {
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        color: #FFFFFF;
      }
      button {
        text-align: left;
        width: 197px;
        height: 27px;
        background: transparent;
        border: none  ;
        font-size: 18px;
        line-height: 27px;
        text-decoration-line: underline;
        color: #FFFFFF;
        opacity: 0.72;
        &.grey {
          font-weight: 700;
          background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }


}
.road_small_img {
  width: 100%;
  position: relative;
  height: 300px;

  .elena {
    transform: translateX(-40%) translateY(-50%);
  }

  img {
    position: absolute;
    height: 300px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 3;
  }

  div.road-line {
    z-index: 1;
    position: relative;
    height: 150px;
    width: 100vw;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 0 1 0;

    .col-12 {
      height: 150px;
      &:before {
        content: '';
        width: 1px;
        height: 100px;
        background-color: rgba(15, 87, 84, 1);
        position: absolute;
        left: 29px;
        bottom: -100px;
      }
      &:after {
        content: '';
        position: absolute;
        left: 29px;
        bottom: -126px;
        width: 28px;
        height: 26px;
        transform: translateY(-50%) translateX(-50%);
        background: url("/images/roadmap/pol.png");
      }
    }

  }
}

.banner-item {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 540px;
  width: 380px;
  padding: 50px 30px 0px 30px;
  text-decoration: none;
  background: transparent;

  .banner-content {
    margin-top: 20px;
    line-height: 1.5;
    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }

  .btn {
    border-radius: 0;
  }

  img {
    display: none;
  }

  .banner-link {
    position: absolute;
    bottom: -10px;
    text-decoration: underline;
    justify-self: end;
    padding: 10px 15px;
  }

  &:hover, &.active {
    background-image: var(--hover-image);
    background-repeat: no-repeat;

    img {
      display: flex;
      align-self: end;
      justify-self: end;
      height: max-content;
      margin-top: auto;
      margin-bottom: 6px;
      margin-left: 10px;
    }

    text-decoration: none;

    .banner-link {
      text-decoration: none;
      color: black;
      background-color: #fff;
      padding: 10px 15px;
    }

    .container-title h3 {
      color: white;
      -webkit-text-fill-color: white;
    }
  }
}

.max-cont {
  h1 {
    width: max-content;
  }
}

.banner-item-small {
  background-image: url('/images/bannerstore/mobbaner.png');
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;

  .container-title {
    h3 {
      width: auto;
      font-weight: 600;
      background: none;
      -webkit-text-fill-color: white;
      text-transform: uppercase;
      color: #FFFFFF;
    }

    img {
      transform: translateY(-50%) translateX(50%);
    }
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 26px;
  }

  .banner-link {
    margin-top: 40px;


    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #FFFFFF;
  }
}

.about-item_servis {
  height: 394px;
  width: 100%;
  font-size: 13px;

  color: rgb(245, 245, 245);
  text-transform: uppercase;
  line-height: 1.35;
  position: relative;
}

.butDrop {
  background-color: initial;
  border: none;
  text-decoration: underline;

  .top-info {
    padding-bottom: 5px;
    font-size: 18px;

    font-weight: bold;
    color: rgb(255, 255, 255);
    margin-bottom: 0;

    img {
      margin-left: 10px;
    }

    a {
      text-decoration: underline;

    }
  }
}

.main-map {
  .container-title {
    h1 {
      margin-bottom: 30px;
    }
  }
  p.top-info {
    margin-left: 40px;
    transform: translateY(20px);
  }
  ul {
    padding: 0;
    margin-bottom: 30px;
  }
  .map_li_case {
    text-decoration: none;
    list-style: none;
    margin-right: 30px;

    a:hover {
      color: #44c540;
    }
  }
  .case-name {
    display: none;
  }
}

.top-info {
  font-weight: 400;
  color: rgb(242, 242, 242);
  margin-bottom: 0;
  transform: translateY(5px);

  a {
    text-decoration: underline;

    &:hover {
      color: #44c540;
    }
  }
}

.top-mid {
  width: 100%;
}

.top-img {
  margin-bottom: 25px;
}

.serv-block_dop {
  top: -30px;
  z-index: 200;
}

.top-box {

  color: rgb(242, 242, 242);
  line-height: 1.5;
  text-align: center;
  width: 280px;
}

.news-pos {
  margin: 30px;
  margin-left: 40px;
  margin-top: 50vh;
  width: 428px;
}

.news {
  position: relative;
  padding: 25px 10px 10px 20px;
  width: 100%;
  height: 150px;
  background: linear-gradient(90deg, #0C7954 0%, #21A220 100%);

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 380px;
    height: 10px;
    border: 380px solid transparent;
    border-top: 10px solid rgba(23, 95, 148, 0.45);

    background: linear-gradient(90deg, #186093 0%, #21A221 100%);;
  }

}
button {
  border-radius: 0;
}
.news-dots {
  position: absolute;
  left: -30px;
  display: flex;
  flex-direction: column;

  button {
    background: none;
    border: none;
    padding: 5px;

    div {
      border-radius: 50%;
      background-color: #26679A;
      height: 13px;
      width: 13px;
    }

    &.active div {
      background-color: #47A144;;
    }

  }


}

.container-title {
  width: 100%;
  padding: 0;

  h1 {
    margin-bottom: 50px;
    font-size: 41px;
    color: rgb(50, 223, 40);
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.22;
    background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h3 {
    font-size: 25px;
    background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.3;
    margin-bottom: 0;
  }

  h3.active {
    color: #fff;
  }

  h4 {

    width: max-content;
    color: rgb(242, 242, 242);
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.5;
    background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.text.col {
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.texnology {
  .h-120 {
    height: 135px;
  }

  .snake-block-text {


    color: rgb(245, 245, 245);
    line-height: 1.5;
    text-align: left;
  }

  .snake-block {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0;

    div {
      width: 50%;
    }

    &.snake-block-1 {
      border-top: $snake;
      box-shadow: 0 -16px 16px -16px rgb(0 0 0 / 67%),
      inset 0 16px 16px -16px rgb(0 0 0 / 67%);
      position: relative;

      .snake-block-img-top-1 {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-52%) translateX(-33%);
      }

      .snake-block-text-top-1 {
        width: 223.329px;
        transform: translateX(-15%) translateY(-5px);
        height: 140px;

      }

      .snake-block-img-top-12 {
        position: absolute;
        top: 0;
        left: 40%;
        transform: translateY(-52%);
      }

      .snake-block-text-top-12 {
        width: 260px;
        transform: translateX(28%) translateY(-5px);
        height: 140px;
      }
    }

    &.snake-block-2 {
      .snake-block-img-top-2 {
        position: absolute;
        top: 0;
        left: 3%;
        transform: translateY(-52%);
      }

      .snake-block-text-top-2 {
        width: 300px;
        height: 140px;
      }
    }

    height: 370px;

    &.snake-block-3, &.snake-block-4 {
      position: relative;

      .snake-block-img-top-3 {
        position: absolute;
        top: -52%;
        left: 18%;
      }

      .snake-block-text-top-3 {
        width: 200px;
        height: 100px;
        position: absolute;
        left: 38%;
      }

      .snake-block-img-top-4 {
        position: absolute;
        top: -46%;
        left: -8%;
      }

      .snake-block-text-top-4 {
        width: 270px;
        height: 100px;
        position: absolute;
        left: 12%;
      }

      transform: translateY(-12px);
    }

    &.snake-block-5, &.snake-block-6 {
      transform: translateY(-24px);
      position: relative;

      .snake-block-img-top-5 {
        position: absolute;
        top: -46%;
        left: 20%;
      }

      .snake-block-text-top-5 {
        width: 270px;
        height: 100px;
        position: absolute;
        left: 39%;
      }

      .snake-block-img-top-6 {
        position: absolute;
        top: -52%;
        left: -8%;
      }

      .snake-block-text-top-6 {
        width: 300px;
        height: 100px;
        position: absolute;
        left: 12%;
      }
    }

    &.snake-block-7, &.snake-block-8 {
      transform: translateY(-36px);
      position: relative;

      .snake-block-img-top-7 {
        position: absolute;
        top: -52%;
      }

      .snake-block-text-top-7 {
        width: 270px;
        height: 100px;
        position: absolute;
        left: 38%;
      }

      .snake-block-img-top-8 {
        position: absolute;
        top: -46%;
        left: -8%;
      }

      .snake-block-text-top-8 {
        width: 270px;
        height: 100px;
        position: absolute;
        left: 12%;
      }

      .snake-block-img-top-9 {
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 65%;
      }
    }
  }

  .snake-block-9 {
    transform: translateY(-100px);
  }

  .snake-right {
    border: $snake;
    border-bottom-right-radius: 185px;
    border-top-right-radius: 185px;
    border-left: none;
    box-shadow: 0 -16px 16px -16px rgba(0, 0, 0, 0.67), 16px 0 16px -16px rgba(0, 0, 0, 0.67), 0 16px 16px -16px rgba(0, 0, 0, 0.67),
    inset 0 -16px 16px -16px rgba(0, 0, 0, 0.67), inset -16px 0 16px -16px rgba(0, 0, 0, 0.67), inset 0 16px 16px -16px rgba(0, 0, 0, 0.67);

  }

  .snake-left {
    border: $snake;
    border-bottom-left-radius: 185px;
    border-top-left-radius: 185px;
    border-right: none;
    box-shadow: 0 -16px 16px -16px rgba(0, 0, 0, 0.67), -16px 0 16px -16px rgba(0, 0, 0, 0.67), 0 16px 16px -16px rgba(0, 0, 0, 0.67),
    inset 0 -16px 16px -16px rgba(0, 0, 0, 0.67), inset 16px 0 16px -16px rgba(0, 0, 0, 0.67), inset 0 16px 16px -16px rgba(0, 0, 0, 0.67);
    transform: translateX(15px);
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-160 {
  margin-bottom: 160px;
}
.pl-15 {
  padding-left: 15px;
}
.media-pk {
  p, b, h3 {

  }

  .img-shadow img {
    height: fit-content;
    box-shadow: 0 3px 68px 0 rgba(7, 25, 42, 0.59);
    width: 385px;
  }
}

.white-box {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  padding: 0;

  img {
    object-fit: fill;
    margin: 0;
  }
}

.rec.rec-slider-container {
  margin: 0;
}

footer {
  padding: 0 0 60px 0;
  color: white;

  .list-unstyled .footer-mes a {
    height: 50px;
  }
  li {
    &.footer-mes {
      margin-bottom: 5px!important;
    }
    margin-bottom: 10px!important;
  }
  a {
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: #44c540;
      transition: all 0.5s;
      text-decoration: none;
    }
  }

  .footer_brand img {
    margin-bottom: 50px;
  }
}

ul {
  margin-bottom: 0;
}

.contact_bg, .modal-dialog {
  color: rgb(245, 245, 245);
  line-height: 1.5;
  text-align: left;
  h3 {
    color: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.5;
    width: max-content;
    margin-bottom: 30px;
  }
  .link-icon {
    height: 25px;

    img {
      height: 100%;
    }

    i {
      font-size: 28px;
    }
  }

  a:hover {
    color: #44c540;
    text-decoration: none;
  }

  b {

    margin-bottom: 0;
  }

  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;


  .contact_img {
    width: 100%;
    height: 340px;
    object-fit: fill;
    margin-bottom: 70px;

    &.contact_title_block {
      height: 380px;
      margin-bottom: 100px;
      background-size: cover;
      background-position: 70% 0;

      .container-title {
        position: relative;
        width: max-content;
        margin-top: 160px;

        &:before {
          content: 'КОНТАКТЫ';
          position: absolute;
          transform: translateY(1px) translateX(1px);
          text-shadow: 2px 2px 20px black;
          color: black;
          font-size: 80px;
          font-weight: 700;
          line-height: 1.22;
          background: black;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        h1.contact_title {
          font-size: 80px;
          width: max-content;
          position: relative;
        }
      }
    }
  }

  textarea.input_contacts {
    height: 100px;
  }

  .btn-color_second {
    color: rgb(242, 242, 242);
    line-height: 1.5;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .privacy {
    color: rgb(255, 255, 255);
    line-height: 1.5;

    a {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: rgb(255, 255, 255);
      }
    }

    margin-bottom: 150px;
  }

  .input_contacts {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    height: 42px;
    background: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgb(184, 184, 184);

    &::placeholder {
      color: rgb(184, 184, 184);
    }
    color: rgb(255, 255, 255);
    line-height: 1.5;

    &:focus {
      outline: none;
    }
  }

  .contacts {
    li {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;

      a {
        display: flex;
        flex-direction: row;
      }

      .contacts_li_img {
        height: 34px;
        width: 50px;
      }
    }
  }
}

.TexnologyCarusel {
  position: relative;
  height: 400px;

  .texnologyCarusel_img_small {
    align-self: center;
    max-width: 360px;
    height: 350px;
    z-index: 1;
  }

  .next {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .back {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 50%;
    transform: translateX(50%) translateY(-50%)
  }

  .texnologyCarusel_text_small {
    width: 100%;
    font-weight: 600;

    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    text-align: center;
    transform: translateY(-80px);
    color: #FFFFFF;
    &.cur {
      display: flex;
      flex-direction: column;
      text-align: center;
      b {
        margin-bottom: 0;
      }
    }
  }
}

.WhereuseCarusel {
  position: relative;
  margin-bottom: 50px;
  height: 240px;

  .rec-slider-container {
    margin: 0;
  }

  .next {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 85%;
    transform: translateX(-5%) translateY(-50%);
  }

  .back {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 85%;
    transform: translateX(5%) translateY(-50%)
  }

  .element {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    position: relative;

    p {
      width: 80%;
      height: 139px;
      margin-top: 24px;
      font-size: 16px;
      line-height: 26px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
    }

    .pagination {
      position: absolute;
      left: 45%;
      top: 72%;
      transform: translateX(50%) translateY(-50%);

      &:hover, &:active {
        color: white;
      }
    }

    &.one {
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(/images/where_use/1.png);

      &:hover {
        background-image: url(/images/where_use/10.png);
      }
    }

    &.two {
      background-size: cover;
      background-image: url(/images/where_use/2.png);

      &:hover {
        background-image: url(/images/where_use/20.png);
      }
    }

    &.third {
      background-size: cover;
      background-image: url(/images/where_use/3.png);

      &:hover {
        background-image: url(/images/where_use/30.png);
      }
    }

    &.fourth {
      background-size: cover;
      background-image: url(/images/where_use/4.png);

      &:hover {
        background-image: url(/images/where_use/30.png);
      }
    }

    &.five {
      background-size: cover;
      background-image: url(/images/where_use/5.png);

      &:hover {
        background-image: url(/images/where_use/50.png);
      }
    }

    &.six {
      p {
        width: 90%;
      }

      background-size: cover;
      background-image: url(/images/where_use/6.png);

      &:hover {
        background-image: url(/images/where_use/60.png);
      }
    }

    &.seven {
      background-size: cover;
      background-image: url(/images/where_use/7.png);

      &:hover {
        background-image: url(/images/where_use/70.png);
      }
    }

    &.eight {
      background-size: cover;
      background-image: url(/images/where_use/8.png);

      &:hover {
        background-image: url(/images/where_use/80.png);
      }
    }
  }
}

.roadsmall_title {
  position: relative;

  .title-road {
    position: absolute;
    bottom: 20px;
    width: 100vw;

    h1 {
      margin-left: 15px;
      width: max-content;
      font-size: 27px;
      line-height: 35px;
    }
  }
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.roadmap_bg {
  background-image: url("/images/roadmap/png/bg.png");
  background-size: cover;
  overflow-y: hidden;
  .roadmap_svg {
    height: 70px;
    width: 100px;
    &.m-zero {
      margin-top: 0;
    }
  }

  .li-before {
    &:after {
      left: -35px;
    }
  }

  .under {
    position: relative;
    z-index: 2;

    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      height: 2px;
      width: 100%;
      background: #444;
      background: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
      z-index: 1;
    }
  }

  .serv_block {
    height: 230px;
    margin-bottom: 30px;

    .svg_block {
      width: 70px;
      height: 70px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 0;
    }

    .btn-color_second {
      display: none;
      margin-top: 15px;
      padding: 15px 30px;
    }

    //&:hover {
    //  .btn-color_second {
    //    display: flex;
    //    margin-top: auto;
    //    justify-self: flex-end;
    //  }
    //}
  }

  .road_img {
    width: 100%;
    height: 340px;
    object-fit: fill;
    margin-bottom: 150px;

    &.roadmap_title_block {
      height: 380px;
      margin-bottom: 150px;
      background-size: cover;
      background-position: 70% 0;

      .container-title {
        position: relative;
        width: max-content;
        margin-top: 120px;

        &:before {
          content: 'ДОРОЖНАЯ КАРТА ПРОЕКТА';
          position: absolute;
          transform: translateY(1px) translateX(1px);
          text-shadow: 0px 2px 30px black;
          color: black;
          font-size: 80px;
          font-weight: 700;
          line-height: 1.22;
          background: black;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        h1.roadmap_title {
          font-size: 80px;
          width: max-content;
          position: relative;
        }
      }
    }
  }

  .btn-color_second {
    margin-top: 30px;
  }

  h2 {

    font-size: 18px;
    color: rgb(255, 255, 255);
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.5;
    width: max-content;
    background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    color: #D9DBE0;
    line-height: 26px;
  }

  .left_block {
    padding-left: 50px;
  }

  .first_block {
    display: flex;
    border-width: 1px;
    border-style: solid;
    height: 295px;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 0 1 1;

    .col-7 {
      p {
        width: 90%;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }

    .road_title {
      transform: translateY(-25px);
      margin-left: 25px;
      font-size: 41px;

      color: rgb(50, 223, 40);
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.22;
      background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: max-content;
    }

    p {
      transform: translateY(-25px);
      margin-left: 25px;
    }

    img {
      transform: translateY(-60px);
    }
  }

  .second_block {
    padding: 80px 25px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    height: 540px;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 1 1 0;

    .w-85 {
      width: 85%;
    }

    p {
      margin-bottom: 20px;
    }

    &:before {
      content: '';
      width: 1px;
      height: 90px;
      background-color: rgba(7, 186, 217, 0.3);
      position: absolute;
      left: -1px;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: -1px;
      top: 90px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }

    .second_img {
      transform: translateY(-30px) translateX(-50px);
    }

  }

  .third_block {
    padding: 75px 0px;
    height: 525px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 0 1 1;

    h2 {
      width: auto;
    }

    img {
      transform: translateY(-40px)
    }

    &:before {
      content: '';
      width: 1px;
      height: 85px;
      background-color: rgba(15, 87, 84, 1);
      position: absolute;
      left: 50%;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 85px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }
  }

  .fourth_block {
    padding: 75px 25px;
    height: 440px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 1 1 0;

    p {
      width: 90%;
    }

    img {
      height: 80%;
      transform: translateY(-33px);
    }

    &:before {
      content: '';
      width: 1px;
      height: 85px;
      background-color: rgba(7, 186, 217, 0.3);
      position: absolute;
      left: -1px;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: -1px;
      top: 85px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }
  }

  .fifth_block {
    position: relative;
    padding: 75px 0px;
    height: 450px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 0 1 1;

    .f_img {
      margin-left: 50px;
      height: 90%;
      transform: translateY(-55px)
    }

    &:before {
      content: '';
      width: 1px;
      height: 80px;
      background-color: rgba(15, 87, 84, 1);
      position: absolute;
      left: 50%;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 80px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }
  }

  .sixth_block {
    padding: 75px 25px;
    height: 450px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 1 1 0;


    p {
      width: 90%;
      margin-bottom: 0;
    }

    &:before {
      content: '';
      width: 1px;
      height: 80px;
      background-color: rgba(7, 186, 217, 0.3);
      position: absolute;
      left: -1px;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: -1px;
      top: 85px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }

    .f_img {
      height: 90%;
      transform: translateY(-40px);
    }
  }

  .seventh_block {
    padding: 75px 0;
    height: 420px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 0 1 1;

    .f_img {
      height: 90%;
      transform: translateY(-55px);
    }

    .road_title {
      margin-bottom: 20px;
      font-size: 41px;
      color: rgb(50, 223, 40);
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.22;
      background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: max-content;
    }

    &:before {
      content: '';
      width: 1px;
      height: 100px;
      background-color: rgba(15, 87, 84, 1);
      position: absolute;
      left: 50%;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 100px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }
  }

  .eighth_block {
    padding: 80px 25px;
    height: 535px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 1 1 0;

    p {
      width: 90%;
    }

    img {
      transform: translateY(-30px);
    }

    &:before {
      content: '';
      width: 1px;
      height: 95px;
      background-color: rgba(7, 186, 217, 0.3);
      position: absolute;
      left: -1px;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: -1px;
      top: 95px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }
  }

  .ninth_block {
    padding: 75px 0;
    height: 400px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 0 1 1;

    .f_img {
      height: 90%;
      transform: translateY(-100px);
    }

    &:before {
      content: '';
      width: 1px;
      height: 90px;
      background-color: rgba(15, 87, 84, 1);
      position: absolute;
      left: 50%;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 90px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }
  }

  .tenth_block {
    p {
      width: 90%;
    }

    padding: 120px 40px;
    height: 555px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 1 0 0;

    .f_img {
      transform: translateY(-45px);
    }

    &:before {
      content: '';
      width: 1px;
      height: 140px;
      background-color: rgba(7, 186, 217, 0.3);
      position: absolute;
      left: -1px;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: -1px;
      top: 140px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }
  }

  .dop_block {
    padding: 80px 40px;
    padding-left: 50px;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 1 0 0 0;

    &:before {
      content: '';
      width: 1px;
      height: 90px;
      background-color: rgba(15, 87, 84, 1);
      position: absolute;
      left: 0;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 90px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }
  }
}

.roadmap_small {
   p {
    font-size: 16px;
  }

  .container-title h1 {
    font-weight: 800;
    font-size: 20px;
    line-height: 33px;
    text-transform: uppercase;
    &.mb-0 {
      margin-bottom: 0;
    }
  }

  .road_title {
    h1 {
      font-weight: 800;
      font-size: 27px;
      line-height: 35px;
      text-transform: uppercase;
      background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: max-content;
      padding-left: 30px;
      margin-right: 10px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 17.5px;
        left: 0;
        width: 28px;
        height: 26px;
        transform: translateX(-15%) translateY(-50%);
        background: url("/images/roadmap/pol.png");
      }
    }

    margin-left: -15px;
    padding-left: 15px;
    margin-bottom: 30px;
    width: 100vw;
    box-sizing: border-box;

    display: flex;
    align-items: center;

    .road_title_line {
      flex-grow: 1;
      height: 1px;
      background-color: rgba(15, 220, 130, 0.3);
      transform: translateY(-50%);
    }
  }

  .banner-link {
    margin-top: 20px;
    width: 100%;
    height: 60px;

    background: linear-gradient(90deg, #1D8D60 0%, #3CB03F 100%);
    border: none;
    outline: none;


    font-weight: 600;

    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
    margin-bottom: 50px;
  }
}

.roadmap_small_btn {
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;

  button {
    outline: none;
    border: none;
    height: 50px;
    width: 54px;
    background: transparent;


    font-weight: 500;

    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;

    &.active {
      background: linear-gradient(90deg, #1D8D60 0%, #3CB03F 100%);
    }

  }
}

//petr
.rec-pagination {
  position: absolute;
  bottom: 0;
}

.pagination {
  max-width: 250px;
}

.pagination-top {
  li {
    list-style-type: none;
  }
}

.sl {
  width: 250px;
  height: 57px;
  margin-top: 40px;
}

.region:hover {
  //fill: url("/images/keys/подложка.jpg");
  //fill: #000;
  //background-size: cover;
  //box-shadow: 0 0 10px rgba(0,0,0,0.5);
  //background-image:url("/images/keys/подложка.jpg") ;
}


.line {
  z-index: 100;
  width: 100%;
  background-color: #0d2a5a;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .bt-blue {
    padding: 15px 33px;
    background-color: #1756b9;
    border: none;
    border-radius: 0;

    line-height: 27px;


    height: max-content;
  }

  .bt-green {
    padding: 15px 33px;
    border: none;
    border-radius: 0;

    line-height: 27px;


    background-color: #38ab4e;
    background: linear-gradient(45deg, #0c7953 40%, #21a220 77%);
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;

    .vector {
      margin-left: 18px;
      background-image: url("/images/servicesPage/Vector.png");
      width: 33px;
      height: 17px;
      &.trans {
        transform: scaleX(-1);
      }
    }
  }

  .manager {

    .m {
      padding-top: 25px;
      font-size: 23px;

      color: #32df28;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.22;
      margin-bottom: inherit;
      background: linear-gradient(
                      90deg, #07bad9, #13f11f);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .s {

    }
  }
}

.sliders-refact {
  .bg-serv {
    background-color: #031823;

    .point {
      border: none;
      background-color: initial;
    }

    .services_news_block {
      z-index: 2;
      overflow: hidden;
      height: 340px;

      .bot-serv {
        border-radius: inherit;
        background: linear-gradient(45deg, #0c7953 40%, #21a220 77%);
        margin-bottom: inherit;

        .vector-test {
          margin-left: 17px;
          background-image: url("/images/servicesPage/Vector.png");
          width: 33px;
          height: 17px;
        }

        &.btn {
          font-size: 18px;
          padding: 15px 32px;
        }
      }

      .img-test {
        z-index: 1;
        left: 300px;
        position: absolute;
        background-image: url("/images/servicesPage/planet.png");
        background-position: center center;
        background-size: cover;

        height: 340px;
        width: 865px;
      }
    }

    .slider-service {
      position: relative;
    }

    .slider-two {
      position: absolute;
      top: 0;

      .img-2 {
        position: absolute;
        left: 400px;
        top: -100px;
        z-index: 2;
        width: 715px;
        height: 420px;
        background-image: url("/images/servicesPage/M2.png");
        background-size: cover;
      }
    }
  }
}

.fon-mobil-serv {
  background-image: url("/images/servicesPage/back-phone.png");
  background-repeat: no-repeat;
}

.btn-pagination_serv {
  border: none;
  outline: none;
  background: transparent;
  color: white;
  padding-left: initial;
  font-weight: 600;
  &:hover, &.active {
    font-weight: bold;
    background: #44C540;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.serv-block {
  .text-serv {
    font-size: 18px;
  }

  #new-list {
    display: none;
  }

  .min-img-block img {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .btn {
    margin-top: 30px;
    border: none;
  }

  .element {
    padding-left: 30px;
    padding-right: 30px;
    border-bottom: 2px solid #0c2143;
  }

  .center {
    border-left: 2px solid #0c2143;
    border-right: 2px solid #0c2143;
    padding-left: 30px;
    padding-right: 30px;
  }

  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    //background-image: url(/images/servicesPage/bacgrayng.png);
    background-repeat: no-repeat;
    height: 270px;
    //margin-right: 20px;
    //margin-left: 20px;
    background-position-y: 50%;
  }

  .img-c {
    background-image: url(/images/servicesPage/bacgrayng.png);
    background-repeat: no-repeat;
    height: 270px;
    background-position-y: 50%;
    margin-right: 20px;
    margin-left: 20px;
  }

  .img-3 {
    background-image: url(/images/servicess/3.jpg);
    height: 203px;
    margin-right: 40px;
  }

  .img-4 {
    background-image: url(/images/servicess/4.jpg);
    height: 203px;
    margin-right: 40px;
  }

  .img-c-5 {
    background-image: url(/images/servicess/5.jpg);
    height: 203px;
    margin-right: 20px;
    margin-left: 20px;
  }

  .img-6 {
    background-image: url(/images/servicess/6.jpg);
    height: 203px;
    margin-right: 40px;
  }

  .img-7 {
    background-image: url(/images/servicess/7.jpg);
    height: 203px;
    margin-top: 60px;
    margin-right: 40px;
  }

  .img-c-8 {
    background-image: url(/images/servicess/8.jpg);
    height: 203px;
    margin-top: 60px;
    margin-right: 20px;
    margin-left: 20px;
  }


  .request {
    margin-left: 0;
    margin-top: 20px;
    margin-right: 20px;

    p {
      margin-left: 20px;
      margin-bottom: 25px;
    }
  }

  .basket {
    margin-top: 8px;
    border: none;
    background: none;
    background-image: url("/images/servicesPage/basket.png");
    background-repeat: no-repeat;
    width: 30px;
    height: 29px;
    &.grey {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }

  .vector {
    margin-left: 17px;
    background-image: url("/images/servicesPage/Vector.png");
    width: 33px;
    height: 17px;
  }

  .text {
    font-weight: 400;
    font-size: 18px;
    margin-top: 30px;
    height: 100px;
    //margin-right: 40px;
  }

  .bottom {
    position: relative;

    button {
      border-radius: inherit;
      background: linear-gradient(45deg, #0c7953 40%, #21a220 77%);
      margin-bottom: inherit;
    }
  }

  ul.hr {
    margin-top: 190px;
    //margin: 0; /* Обнуляем значение отступов */
    padding: 4px; /* Значение полей */
  }

  ul.hr li {
    cursor: pointer;
    display: inline; /* Отображать как строчный элемент */
  }

  ul.hr li.activ {
    color: #38ab4e;
  }

  margin-top: 100px;

  img.min-img {
    max-width: 270px;
    margin-top: 60px;
    margin-left: 7px;
  }
}
.text-next{
  margin-top: 90px;
  img{
    padding-top:32px;
  }
}
.serv-detal{
  font-size: 18px;
  font-weight: 400;
}
.imgServDetailed-big{
  align-self: center;
  justify-content: space-evenly;
  width: auto;
  height: 300px;
}
.btn-serv_more {
  color: white;
  width: 250px;

  &:hover {
    color: white;
  }

  border-radius: 0;
  background: linear-gradient(45deg, #0c7953 40%, #21a220 77%);
  margin-bottom: 0;

  .vector_more {
    margin-left: 17px;
    background-image: url("/images/servicesPage/Vector.png");
    width: 33px;
    height: 17px;
  }

  &.btn {
    padding: 15px 32px;
  }
}

.smi-btnon {
  width: 100%;
  height: max-content;
  margin: 0;
}
#myDIV{
  margin-top: 37px;
}
.page-items {
  padding: initial;

  .page-link {
    margin-right: 50px;
    border: none;
    color: white;


    padding: inherit;
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    outline: none;

    &.active {
      background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.active_before {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 7px;
        left: -25px;
        width: 17px;
        height: 18px;
        background: url("/images/servicesPage/min-point.png");
      }
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.text-next {
  .request {
    cursor: pointer;
    margin-left: 0;
    margin-top: 20px;
    margin-right: 40px;

    p {
      margin-left: 20px;
    }
  }

  .br-bt {
    border-bottom: inherit;
  }

  .btn {
    position: relative;

    width: 247px;
    height: 58px;
    background-color: #38ab4e;
    border-radius: inherit;
    border: initial;
    background: linear-gradient(45deg, rgb(12, 121, 83) 40%, rgb(33, 162, 32) 77%);;

    p {
      margin-left: 20px;
      margin-top: 6px;
      text-align: left;
    }
  }

  .align-self-left {
    position: relative;
    margin-right: initial;
    margin-left: 40px;
  }

  .img-l {

    height: 203px;
    margin-left: 40px;
  }

  .text-l {
    margin-top: 30px;
    height: 100px;
    margin-right: 20px;
    margin-left: 20px;
  }

  .min-img-block {
    padding-top: 30px;

    img {
      height: auto;
      display: block;
      margin: 0 auto;
      border: none;
    }
  }
}

.roadmap_bg_serv {
  .min-img-servDetailed {
    p {

      line-height: 27px;
      color: #D9DBE0;
      margin-bottom: inherit;
    }
  }
}
.roadmap_bg_serv .third_block_serv .min-img{
  align-items: center;
  justify-content: center;
  display: flex;

}
 .marg-h1{
  margin-bottom: 22px!important;
}
 .texnology_img_block p{
   text-align: center;
 }
.texnology_img_block img{
  display: block;
  margin: 0 auto;
}
.min-img-servDetailed {
  margin-top: 21px;
  padding: 0;
  padding-bottom: 74px;

  img {
    //height: 45px;
    //width: 43px;
    margin-right: 20px;
  }

}
.min-img-block{
  display: flex;
}
.imgServDetailed-result-block {
  margin-bottom: 45px;

  img {
    width: 55px;
    height: 55px;
    //margin-right: 10px;
  }
}

.roadmap_bg_serv {


  .container-title.small {
    h1 {
      width: max-content;
    }
  }

  .roadmap_svg {
    height: 70px;
    width: 110px;

  }

  .under {
    position: relative;
    z-index: 2;

    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      height: 2px;
      width: 100%;
      background: #444;
      background: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
      z-index: 1;
    }
  }

  .serv_block {
    height: 300px;
    margin-bottom: 30px;

    .svg_block {
      width: 70px;
      height: 70px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 0;
    }

    .btn-color_second {
      display: none;
      padding: 15px 30px;
    }

    &:hover {
      .btn-color_second {
        display: flex;
        margin-top: auto;
        justify-self: flex-end;
      }
    }
  }

  .road_img {
    width: 100%;
    height: 340px;
    object-fit: fill;
    margin-bottom: 150px;


  }

  h2 {

    color: rgb(255, 255, 255);
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.5;
    width: max-content;
    background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
  }

  p {
    margin-left: 15px;
    color: #D9DBE0;
    line-height: 1.5;
  }

  .left_block {
    padding-left: 50px;
  }

  .first_block {
    display: flex;
    border-width: 1px;
    border-style: solid;
    height: 300px;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 0 1 1;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }

    .road_title {
      transform: translateY(-25px);
      margin-left: 20px;
      font-size: 41px;

      color: rgb(50, 223, 40);
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.22;
      background: linear-gradient(90deg, rgb(7, 186, 217), rgb(19, 241, 31));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: max-content;
    }

    p {
      transform: translateY(-25px);
      margin-left: 20px;
    }

    img {
      transform: translateY(-60px);
    }
  }

  .texnology_img {
    margin: 0 15px;
    display: flex;
    flex-wrap: wrap;

    .texnology_img_block {
      width: 50%;
      margin-bottom: 16px;

      img {
        margin: auto;
        //width: 180px;
        //height: 180px;
      }
    }
  }

  .first_block_serv {
    padding: 0px 25px;
    //height: 490px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 1 1 1 0;

    &:before {
      content: '';
      width: 1px;
      height: 90px;
      background-color: rgba(7, 186, 217, 0.3);
      position: absolute;
      left: -1px;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: -1px;
      top: 90px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }
  }

  #stuff {
    display: none;
  }

  .second_block_serv {
    //min-height: 570px;
    padding: 0px 25px;
    padding-left: 70px;
    //height: 490px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 0 1 1;

    h1 {
      &:after {
        content: '';
        position: absolute;
        left: -70px;
        width: 28px;
        height: 26px;
        transform: translateY(7px);
        background: url("/images/roadmap/pol.png");
      }
    }
  }

  .third_block_serv {
    //min-height: 570px;
    padding: 0px 25px;
    //height: 490px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 0 0 0;

    &:before {
      content: '';
      width: 1px;
      height: 90px;
      background-color: rgba(7, 186, 217, 0.3);
      position: absolute;
      left: -1px;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: -1px;
      top: 90px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }

  }

  .second_point {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      right: -50px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }
  }


}

.imgServDetailed {
  //background: url("/images/imgServDetailed/bug.png") no-repeat;
  //min-height: 410px;
  //background-position: center;

}

.imgServDetailed-margin-one {
  margin: 66px 30px 60px 50px;
}
.imgServDetailed-margin-tho{
  margin: 66px 30px 60px 0;
}
.imgServDetailed-margin-thri{
  margin: 66px 30px 60px 0;
}
#sevkav {
  background-size: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-image: url("/images/cases/подложка.jpg");
}


.not-marge-bot {
  margin-bottom: 10px !important;

  .vector {
    background-image: url("/images/servicesPage/Vector.png");
  }
}

.guuruq {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  backdrop-filter: blur(5px);
  background-color: transparent;
  font-size: 1.3em;
  background-color: rgb(34 146 191);
  content: "";
  height: 10px;
  width: 10px;
  box-shadow: 0 0 1px 2px rgb(20 57 71);
  border-radius: 50%;
  outline: none;
}

.cJeRRR {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  background-color: rgba(38, 187, 31);
  font-size: 1.3em;
  content: "";
  height: 10px;
  width: 10px;
  box-shadow: 0 0 1px 3px rgb(28 110 37);
  border-radius: 50%;
  outline: none;
}
.form-info {
  color: rgb(184, 184, 184);
  font-size: 15px;
  margin-bottom: 20px;
  height: 0;
  transform: translateY(-15px);
}
.vector-on {
  display: none;
}
.number-cont {
  .w-100.input_contacts {
    display: none;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: white !important;
  border-bottom: 1px solid #b8b8b8;
  -webkit-transition: background-color 999999s ease-out;
  -webkit-transition-delay: 999999s;
}
.react-tel-input {
  margin-top: 10px;
  margin-bottom: 20px;

  .flag-dropdown {
    display: none;
  }
  .special-label + .form-control {
    &::placeholder {
      color: rgb(184, 184, 184);
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    select {
      display: none;
    }
    padding: 0;
    font-size: 18px;
    width: 100%!important;
    line-height: 30px;
    margin: 0;
    background: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #b8b8b8;
    color: white;
    height: 42px;
  }
}
.result-popup.modal .modal-dialog {
  margin-top: 200px;
  max-width: 564px;
  width: 100%;
  min-width: 200px;
  max-height: none;
  height: max-content;

  .modal-content {
    max-width: 564px;
    height: max-content;
    background: #001134;
    h1  {
      font-size: 25px;
      line-height: 27px;
      font-style: normal;
      font-weight: bold;
      text-transform: none;
      text-align: center;
    }
    p {
      display: flex;
      align-items: center;
      text-align: center;
    }
    .btn-color_second {
      width: auto;
      font-size: 18px;
      padding: 15px 32px;
    }
  }
}
.keys-green-block {
  background: linear-gradient(90deg, #0C7954, #21A220);
  padding: 25px 40px 25px 40px;
}
.btn-ontop {
  position: absolute;
  bottom: -50px;
  right: 50%;
  display: flex;
  align-items: center;

  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  text-decoration-line: underline;
  color: #FFFFFF;
  cursor: pointer;
  transform: translateX(50%);

  .btn-img-ontop {
    margin-left: 24px;
    border-radius: 50%;
    background: linear-gradient(90deg, #0C7A54 0%, #21A221 100%);
    width: 66px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 40px;
      transform: rotate(-90deg);
    }
  }

}

.keys {
  position: relative;

  color: #D9DBE0;
  .map {
    transform: translateY(-70px);
  }

  .case-cards {
    margin: 0 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .case-card_block {
      width: 50%;
      margin-bottom: 25px;

      .img_block {
        margin: auto;
        height: 150px;
        margin-bottom: 10px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }

      p {
        width: 90%;
        text-align: center;
        margin: auto;
      }
    }
  }

  .first_block_cases {
    padding: 90px 40px 90px 40px;;
    display: flex;
    flex-direction: column;
    border-width: 1px;
    border-style: solid;
    border-bottom: 0;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 1 1 0 0;

    .container-title {
      width: max-content;

      h1 {
        position: relative;
        margin-bottom: 10px;

        &:before {
          content: '';
          position: absolute;
          left: -41px;
          top: 26px;
          width: 28px;
          height: 26px;
          transform: translateY(-50%) translateX(-50%);
          background: url("/images/roadmap/pol.png");
        }

        &:after {
          content: '';
          width: 1px;
          height: 110px;
          background-color: rgba(7, 186, 217, 0.3);
          position: absolute;
          left: -41px;
          top: -90px;
        }
      }
    }

    img {
      transform: translateY(-50px);
    }
  }

  .third_block_cases {
    padding: 80px 0px 70px 25px;
    //height: 490px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 1 0 0 1;
    border-bottom: 0;

    .container-title {
      h1 {
        width: max-content;
        margin-bottom: 20px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: -40px;
          top: 26px;
          width: 28px;
          height: 26px;
          transform: translateY(-50%) translateX(-50%);
          background: url("/images/roadmap/pol.png");
        }
      }
    }

    .li-before:after {
      left: -53px;
    }
  }

  .second_point_cases {
    .image_block {
      padding: 30px 70px 30px 0;
    }

    .text_block {
      .empty_text_block {
        height: 80px;
        flex-grow: 1;
        position: relative;

        &:before {
          content: '';
          width: 1px;
          background-color: #0a5359;
          position: absolute;
          left: -40px;
          top: 0;
          bottom: 0;
        }
      }

      .empty_text_block-two {
        height: 70px;
        flex-grow: 1;
      }
      .main_text_block {
        transform: translateY(-24px);
      }

      padding: 0 70px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .li-before:after {
        left: -40px
      }

      .container-title {
        h1 {
          width: max-content;
          margin-bottom: 15px;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: -40px;
            top: 26px;
            width: 28px;
            height: 26px;
            transform: translateY(-50%) translateX(-50%);
            background: url("/images/roadmap/pol.png");
            z-index: 1;
          }
        }
      }
    }

    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 1 1 1 0;

  }

  .fourth_point_cases {
    padding: 60px 30px 60px 25px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 1 0 1 1;

    .container-title {
      h1 {
        margin-bottom: 20px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: -40px;
          top: 26px;
          width: 28px;
          height: 26px;
          transform: translateY(-50%) translateX(-50%);
          background: url("/images/roadmap/pol.png");
        }
      }
    }

    .li-before:after {
      left: -52px;
    }

  }

  .fifth_point_cases {
    .text_block {
      padding: 0 70px 0 0;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .empty_text_block {
        height: 80px;
        flex-grow: 1;
        position: relative;

        &:before {
          content: '';
          width: 1px;
          background-color: #0a5359;
          position: absolute;
          left: -40px;
          top: 0;
          bottom: 0;
        }
      }

      .empty_text_block-two {
        height: 60px;
        flex-grow: 1;
      }

      .main_text_block {
        display: flex;
        flex-direction: column;
        transform: translateY(-24px);
      }
    }

    .image_block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 70px 40px 0;

      .li-before {
        margin-left: 35px;
        width: 35%;

        &:after {
          left: -30px;
          top: 5px;
        }
      }
    }

    .li-before:after {
      left: -40px;
    }

    .container-title {
      h1 {
        margin-bottom: 20px;
        width: max-content;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: -40px;
          top: 26px;
          width: 28px;
          height: 26px;
          transform: translateY(-50%) translateX(-50%);
          background: url("/images/roadmap/pol.png");
        }
      }
    }

    display: flex;
    border-width: 1px;
    border-top-width: 0;
    border-style: solid;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 1 1 0;

  }

  .sixth_point_cases {
    padding: 60px 40px 0px 40px;
    //height: 490px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-image: linear-gradient(90deg, rgba(7, 186, 217, 0.3), rgba(19, 241, 31, 0.3)) 1% 100%;
    border-image-slice: 0 0 0 0;


    &:before {
      content: '';
      width: 1px;
      height: 90px;
      background-color: rgba(7, 186, 217, 0.3);
      position: absolute;
      left: -1px;
      top: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      left: -1px;
      top: 86px;
      width: 28px;
      height: 26px;
      transform: translateY(-50%) translateX(-50%);
      background: url("/images/roadmap/pol.png");
    }
    .keys-green-block {
      margin-top: 0;
    }
    .div-el {
      display: flex;
      transform: translateX(-40px);

      .el {
        margin-bottom: 60px;
        //width: 100px;
        height: auto;

        .img-el {
          height: 140px;
          align-self: center;
        }

        .text {
          margin-left: 34px;
          .title {
            width: auto;
            margin-bottom: 11px;
            font-size: 16px;
            line-height: 27px;
            /* or 150% */
            align-items: center;
            text-transform: uppercase;
            color: #32df28;
            font-weight: 800;
            background: linear-gradient(90deg, #07bad9, #13f11f);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .content {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #D9DBE0;
          }
        }
      }
    }
  }

  .keys-title {
    .img img {
      width: 90%;
      transform: translateY(-50px);
    }

    .keys-block {
      .container-title h1 {
        margin-bottom: 34px;
        margin-left: inherit;
      }

    }

    .tab-content .tab-pane .about-text {
      margin-bottom: 30px;
    }
  }

  .region {
    background-image: url("/images/cases/krasnodar.png");
    height: 282px;
    width: 100%;

    .region-block {
      padding-top: 47px;
      padding-left: 62px;

      .container-title h1 {
        width: max-content;
        margin-bottom: 20px;
      }
    }

    .anchor {
      margin-top: 10px;

      a {
        color: #D9DBE0;
        margin-right: 25px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

}


.new-list {
  display: none;
}

.link-serv {
  display: flex;
  align-items: flex-end;
  margin-left: 30px;
  text-decoration: underline;
}
.Serv-padding{
  padding: inherit;
}

.text-Serv-Detailed{
  padding: inherit;
}
.pd-none {
  padding-left: inherit;
  padding-right: inherit;

}
.agronomist_block{
  justify-content: flex-end;
  .agro{
    max-width: 400px;
  }
}
.mobil-img {
  display: none;
  margin-left: inherit!important;
}

.mobil-case-img {
  display: none;
}

.mobil-case-btn {
  display: none;
}

.mobil-img-serv {
  display: none;
}

.desk-img {
  display: flex;
}
.slider-service{
  h1{
    margin-bottom: 20px;
  }
  button{
    margin-top:44px ;
  }
  .serv-block{
    margin-top: 63px;
  }
}

.el-1{
  padding-left: 30px;
  padding-right: 30px;
}
.desk-img-serv {
  display: flex;
}

.serv-detal {
  .text-img-min {
    p {
      width: 10px;
      margin-left: 21px;
      margin-bottom: inherit;
    }
  }
  h1 {
    font-size: 33px;
    margin-bottom: 37px;
  }

  .breadcrumbs {
    margin-left: 1px;
    padding-left: 1px;
    font-size: 15px;
    line-height: 1;
    /* identical to box height, or 333% */
    margin: 16px 0 45px;
    color: #D0D0D0;

    a, a:hover {
      font-size: 15px;
      color: #D0D0D0;
      text-decoration: none;
      margin: 0 12px;
    }

  }
}

.case-region-сarusel {
  margin-top: 30px;

  .case-region {


    font-size: 23px;
    line-height: 35px;
    margin-bottom: 20px;
    color: #FFFFFF;
    text-decoration: none;
  }
}
.serv-block_dop{
  .text-serv{
    line-height: 27px;
  }
}
.element, .el-1, .center {
  .request p{
    line-height: 20px;
    font-size: 20px;
    font-weight: 400;
    padding-top: 10px;

  }
}
.pagination-bott{
  padding-right: 50px;
}
.services-body{
  .container-title h1{
    margin-left: inherit;
  }
  //.crumbs{
  //  div{
  //    font-weight: 400;
  //  }
  //  ul{
  //    a{
  //    font-weight: 400;
  //  }}
  //  margin-left: inherit;
  //}

  .crumbs{
    margin-left: inherit;
    .servis{

    }
  }

}
.svg-detail-one{
  margin-bottom: 25px;
}
.vector-det{
  width: 41px!important;
  height: 21px!important;
  margin-top: 15px;
  margin-right: 15px;

}

@media (min-width: 992px) and (max-width: 1200px) {
  .serv-block img.min-img{
    max-width: 240px;
  }
  .serv-block .request p {
    //font-size: 14px;
  }
  .banner-arrow {
    &.next {
      right: -20px;
    }

    &.back {
      left: -20px;
    }
  }
}

@media (max-width: 1234px) {
  .sliders-refact .bg-serv .services_news_block .img-test {
    left: inherit;
    width: 100%;
  }
  .line .bt-blue {
    font-size: inherit;
  }
  .mouse {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .map .case-name {
    top: 10%;
    font-size: 30px;
    line-height: 40px;
  }

  .line .bt-blue{
    font-size: 15px;
  }
  .line .bt-green{
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .roadmap_bg_serv .second_block_serv h1:after{
    background-color: rgba(7, 186, 217, 0.3);
    position: absolute;
    content: "";
    width: 240px !important;
    height: 1px !important;
    left: -230px !important;
    top: 18px !important;
    transform: inherit;
  }
  .imgServDetailed-margin-one,.imgServDetailed-margin-tho,.imgServDetailed-margin-thri{
    h1{
      margin-left: 20px;
    }
    h1:before{
      //left: 35px !important;
      top: 0 !important;
      transform: translateX(-30px) translateY(5px) !important;
      content: "";
      position: absolute;
      background: url("/images/roadmap/pol.png");
      width: 26px;
      height: 28px;
    }
    h1:after{
      background-color: rgba(7, 186, 217, 0.3);
      position: absolute;
      content: "";
      width: 240px !important;
      height: 1px !important;
      left: -230px !important;
      top: 18px !important;

    }
  }
  .pagination-top .page-items {
    margin-left: 22px;
  }
  .pagination-top .page-items .page-link {
    margin-right: 10px;
  }
  .text-Serv-Detailed .container-title h1,.text-Serv-Detailed  p,.svg-detail-one, .imgServDetailed-margin-one  p,.imgServDetailed-margin-tho  p,.imgServDetailed-margin-thri .text-p p{
    margin-left: 20px;
  }
  .servis {
    margin-left: 23px;}
  .text-p{
    margin-left: inherit!important;
    padding: inherit!important;
  }
  .imgServDetailed-margin-one{
    margin: 0;
  }
  .third_block_serv{
    .min-img{
      justify-content: flex-start!important;
      order:1;
    }
  }
  .scroll {
    overflow: hidden;
  }
  .roadmap_bg {
    background-image: url(/images/roadmap/png/small_roadmap_bg.jpg);
    background-size: cover;
  }
  .roadmap_bg .roadmap_svg {
    margin-bottom: 24px;
    margin-top: 30px;
  }
  .slider-1_block_small {
    height: 220px;
    .about-arrow {
      top: 50%;
      &.back {
        left: -40px;
      }
      &.next {
        right: -40px;
      }
    }
    .about-item {
      width: 320px;
      height: 250px;
      background-size: 100%;
      background-position: 0% 0%;
    }
    .about-item-title {
      font-size: 19px;
      line-height: 30px;
      height: 200px;
      top: 50px;
      width: 80%;
    }
  }
  .keys .sixth_point_cases .div-el {
    transform: none;
  }
  .main-bg {
    background-image: url(/images/main_bg_small.png);
    background-position: 0% 0%;
  }
  .mb-160 {
    margin-bottom: 50px;
  }
  .serv-block {
    .element {
      border-bottom: inherit;
      .request p{
        line-height: 20px;
      }
    }

    .center {
      border-left: inherit;
      border-right: inherit;
    }
  }
  .mobil-case-img {
    display: block;
  }
  .mobil-case-btn {
    display: block;
  }
  .first_block_serv {
    .imgServDetailed-margin {
      margin: 0;


      .container-title h1 {
        margin-top: 10px;
        margin-left: 10px;
      }

      .container-title h1:before {
        content: "";
        position: absolute;
        left: 10px;
        top: 26px;
        width: 28px;
        height: 26px;
        transform: translateY(-50%) translateX(-50%);
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAMAAACTisy7AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAKyUExURQAAABPyHAe62we62xPyHBPyHBPyHAe62xPyHAe62we62we62xLuKRLuKRLsMBPwIhPwIhPwIhLuKRLsMBLuKRPwIhLuKQe71gnFtBHpOQi9zxLsMBLuKQe62xHpORPwIhLsMBLsMBLsMBDkThHnQxDkThPyHBHpORHnQwe71gnFtBLsMBHnQxLsMBHpOQe71gnCvhHnQxDkThHpOQjAxwi9zwi9zwnCvge71hHpOQjAxwe71gjAxxHnQxHpORLuKQnCvhPyHAi9zxPwIhPyHBLsMAi9zwe71hHnQwi9zwjAxwjAxxDkThHpOQnCvge71hLuKQ/gWQnCvhHnQxPwIhPyHBLsMAe62xPyHBPwIgnFtBDkThPwIhHpOQi9zxHpORLsMBLuKRLuKRHpORLsMBPyHAnCvgjAxxLuKRHnQwi9zwi9zxHpOQe62we71gjAxwi9zwrIqhHnQwe62wjAxwi9zwe71ge62wnFtBHnQwe62wjAxw/gWQjAxwe62wnCvge62w/gWRDkTge71ge62we62we71gnFtAi9zxDkTgnCvge62xHnQwjAxwjAxxHnQw7dZAnCvgrIqgnFtBDkTg/gWQ7dZArIqg/gWRDkTgnFtAnCvg7ZcAnFtAnCvgrIqhDkTgvMnw/gWQ7dZAnCvgzSiBDkTg/gWQ7dZA3WfA7dZA/gWQvMnw7ZcArIqgnFtA7ZcAvPkwnFtArIqgvMnw/gWQ7dZA3WfA7ZcAzSiAnFtA7ZcA3WfArIqg7ZcA7dZA3WfA7dZAvPkwvMnwvMnw3WfA3WfAvPkwzSiAvPkwvPkw3WfAvPkwrIqg3WfAvMnwzSiArIqgvMnwzSiA7ZcAzSiAvPkw7ZcAvPkwvMnwzSiAzSiAzSiA3WfAvPkwvMnw7ZcA7dZArIqg/gWQnFtBDkTqt7/bYAAADcdFJOUwAEAggCAwgBAQMOBAECAQEDBwUDDA4YAf4CAQgqB5wbXRQ+/vH8ELvkAvyA+CbVGPHQ+Hi7XYD4KgjVBZz850TkDj4vHaMDDASjAucBF/xEt/7Q/mQ4wRstSfjudvgm8uOGZFTXE/54pLPBCDIQZPIU/hAd+NeGLwGMSVT6CHazOAHct2MtpO7jBgQTLjIXXP2M+ty68AEBBxkGEP66XPCD/dL2Lv5Emtv+CB/299KDAf4ZBwFSo/fd90Sl3ZoIIQFY99sICCJZIgilWgEfpSGlUqMBIgjdWSJY3VpLx11+AAABQ0lEQVQoz2NggIGK6VvOXD6wYVqjDwMakPLqmrX5yMmrR/etmtHpIcmHLCfuVjt709nzN04cu7J/9cwiS2EeJEnN7L4luy7ePH779PWDW5f1+ErzIuTYrQt6563fe+nUhXO7ty1f0J+jKMQPl+R0j6ueOHfd4Wu39uxYsWhKZbKuiABco6y+U3r95PlrDu3cvnbx1KZMYyUxQQ6oHCeXhalNUPukhRuXrpwzoSbaUE5BRpQVIsvEHO5vZuIQVXj/3p27Dx4GGmmoa9vLs7GANXLnlud5WhloPYJIPlZV0XNNclZj5ABrLK4qy/I2l4BJKuvYhSVGuoC1cnK1trSV5vs9gUk+tY1PyQiJcGQFmdrdUNfcUZKGkAyOTQiICU0FmotXEq+x+B2E1yt4AwF/8OENePxRhj+y8SYT/AkMb9LEkagBNhIGltlTMigAAAAASUVORK5CYII=);
        z-index: 1;
      }

      .container-title h1:after {
        content: "";
        background-color: rgba(7, 186, 217, 0.3);
        position: absolute;
        width: 220px !important;
        height: 1px !important;
        left: -220px !important;
        top: 24px !important;
      }
    }
  }
  .second_block_serv {
    .imgServDetailed-margin {
      margin: 0;


      .container-title h1 {
        margin-top: 10px;
        margin-left: 10px;
      }

      .container-title h1:before {
        content: "";
        position: absolute;
        left: 10px;
        top: 26px;
        width: 28px;
        height: 26px;
        transform: translateY(-50%) translateX(-50%);
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAMAAACTisy7AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAKyUExURQAAABPyHAe62we62xPyHBPyHBPyHAe62xPyHAe62we62we62xLuKRLuKRLsMBPwIhPwIhPwIhLuKRLsMBLuKRPwIhLuKQe71gnFtBHpOQi9zxLsMBLuKQe62xHpORPwIhLsMBLsMBLsMBDkThHnQxDkThPyHBHpORHnQwe71gnFtBLsMBHnQxLsMBHpOQe71gnCvhHnQxDkThHpOQjAxwi9zwi9zwnCvge71hHpOQjAxwe71gjAxxHnQxHpORLuKQnCvhPyHAi9zxPwIhPyHBLsMAi9zwe71hHnQwi9zwjAxwjAxxDkThHpOQnCvge71hLuKQ/gWQnCvhHnQxPwIhPyHBLsMAe62xPyHBPwIgnFtBDkThPwIhHpOQi9zxHpORLsMBLuKRLuKRHpORLsMBPyHAnCvgjAxxLuKRHnQwi9zwi9zxHpOQe62we71gjAxwi9zwrIqhHnQwe62wjAxwi9zwe71ge62wnFtBHnQwe62wjAxw/gWQjAxwe62wnCvge62w/gWRDkTge71ge62we62we71gnFtAi9zxDkTgnCvge62xHnQwjAxwjAxxHnQw7dZAnCvgrIqgnFtBDkTg/gWQ7dZArIqg/gWRDkTgnFtAnCvg7ZcAnFtAnCvgrIqhDkTgvMnw/gWQ7dZAnCvgzSiBDkTg/gWQ7dZA3WfA7dZA/gWQvMnw7ZcArIqgnFtA7ZcAvPkwnFtArIqgvMnw/gWQ7dZA3WfA7ZcAzSiAnFtA7ZcA3WfArIqg7ZcA7dZA3WfA7dZAvPkwvMnwvMnw3WfA3WfAvPkwzSiAvPkwvPkw3WfAvPkwrIqg3WfAvMnwzSiArIqgvMnwzSiA7ZcAzSiAvPkw7ZcAvPkwvMnwzSiAzSiAzSiA3WfAvPkwvMnw7ZcA7dZArIqg/gWQnFtBDkTqt7/bYAAADcdFJOUwAEAggCAwgBAQMOBAECAQEDBwUDDA4YAf4CAQgqB5wbXRQ+/vH8ELvkAvyA+CbVGPHQ+Hi7XYD4KgjVBZz850TkDj4vHaMDDASjAucBF/xEt/7Q/mQ4wRstSfjudvgm8uOGZFTXE/54pLPBCDIQZPIU/hAd+NeGLwGMSVT6CHazOAHct2MtpO7jBgQTLjIXXP2M+ty68AEBBxkGEP66XPCD/dL2Lv5Emtv+CB/299KDAf4ZBwFSo/fd90Sl3ZoIIQFY99sICCJZIgilWgEfpSGlUqMBIgjdWSJY3VpLx11+AAABQ0lEQVQoz2NggIGK6VvOXD6wYVqjDwMakPLqmrX5yMmrR/etmtHpIcmHLCfuVjt709nzN04cu7J/9cwiS2EeJEnN7L4luy7ePH779PWDW5f1+ErzIuTYrQt6563fe+nUhXO7ty1f0J+jKMQPl+R0j6ueOHfd4Wu39uxYsWhKZbKuiABco6y+U3r95PlrDu3cvnbx1KZMYyUxQQ6oHCeXhalNUPukhRuXrpwzoSbaUE5BRpQVIsvEHO5vZuIQVXj/3p27Dx4GGmmoa9vLs7GANXLnlud5WhloPYJIPlZV0XNNclZj5ABrLK4qy/I2l4BJKuvYhSVGuoC1cnK1trSV5vs9gUk+tY1PyQiJcGQFmdrdUNfcUZKGkAyOTQiICU0FmotXEq+x+B2E1yt4AwF/8OENePxRhj+y8SYT/AkMb9LEkagBNhIGltlTMigAAAAASUVORK5CYII=);
        z-index: 1;
      }

      .container-title h1:after {
        content: "";
        background-color: rgba(7, 186, 217, 0.3);
        position: absolute;
        width: 220px !important;
        height: 1px !important;
        left: -220px !important;
        top: 19px !important;
      }
    }
  }
  .btn-pagination_serv:hover, .btn-pagination_serv.active {
    -webkit-text-fill-color: inherit;
  }
  .text-Serv-Detailed .container-title h1{
    font-size: 20px!important;

  }
  .btn-pagination_serv {
    outline: none;
    border: none;
    height: 50px;
    width: 60px;
    background: transparent;


    font-weight: 500;

    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;

    &:hover, &.active {
      border-radius: inherit;
      padding: 2px;
      background: linear-gradient(
                      90deg, #1D8D60 0%, #3CB03F 100%);
    }
  }
  .slider-1_block .rec-slider-container {
    margin: 0;
  }
  .position-relative .container {
    max-width: 100vw;
    min-width: 100vw;
  }
  .container-title {
    h1 {
      font-weight: 700;
      font-size: 27px;
      line-height: 35px;
      margin-bottom: 30px;
      text-transform: uppercase;

    }
  }
  .serv-aa-title h1 {
    position: relative;

    .img-serv {
      background: inherit;
      border: none;
      position: absolute;
      right: 0;
      top: 0;
      margin-right: 30px;
    }
  }
  .hiden-serv-one {

    .hiden-request {
      margin-left: 15px;
    }

    .hiden-serv-b {
      width: 100%;
    }

    button {
      margin-left: inherit;
    }

    .bottom button {
      margin-left: inherit;
      margin-right: inherit;
    }

    .bottom .hiden-serv-one-but {
      margin-left: 15px;
      margin-top: inherit;
    }
  }
  .text-none {
    display: inherit;
  }
  .desk-img {
    display: none !important;
  }
  .serv-block {
    .bottom {
      button {
        width: 100%;
      }
    }
  }
  .pagination-bott {
    button {
      margin-left: inherit !important;
      margin-right: inherit !important;
    }
  }
  .desk-img-serv {
    display: none;
  }
  .mobil-img-serv {
    display: inherit;
  }
  img.min-img {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    width: inherit !important;
  }
  .serv-aa-title {
    margin-left: 15px;
    .mb-0{
      margin-left: inherit;
    }
  }
  .page-items .page-link.active_before:before {
    background: none;
  }
  .text-none {
    display: none;
  }
  .page-items .page-link.active_before {
    font-weight: 600;
    background: linear-gradient(90deg, #07bad9, #13f11f);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: table;
  }
  .serv-request {
    margin-top: inherit;
  }
  .hiden-serv-one {

    .request p {
      margin-top: 5px;
      margin-bottom: inherit;
    }
  }
  .pagination-top {
    margin-bottom: 50px;
    padding: 0;

    .F {
      display: none;
    }
  }
  .pagination-bott button {
    font-size: 14px;
  }
  .serv-block {
    .new-list {
      margin-left: inherit !important;
      display: block;
      width: 100%;
      margin-left: inherit;
    }

    .img {

      height: inherit;
      margin-right: inherit;
      background-position-y: 50%;
    }

    .align-self-left {
      margin-left: inherit;
    }

    ul.hr {
      margin-top: inherit;
    }

    .img-c {

      margin-right: inherit;
      margin-left: inherit;
    }

    .text-next {
      display: none;

      img {
        margin-top: inherit;
        margin-left: 7px;
      }
    }

    .min-img-block {
      display: block;
      margin-left: auto !important;
      margin-right: auto !important;
      width: 250px;

      img {
        margin: initial;
        height: 240px;
      }
    }

    .text {
      //margin-left: 20px;
      margin-right: 20px
    }

    .request {
      margin-left: 20px;
    }

    .bottom {
      //margin-right: 30px;
      margin-left: inherit;
    }

    .img-c {
      height: inherit;
    }

  }
  .mobail-but {
    margin-left: inherit;
  }
  .serv-block .green-button {
    width: 100%;
  }
  .serv-block {
    margin-top: 10px;
  }
  .roadmap_bg_serv {
    .first_block_serv {
      display: contents;
    }

    .first_block_serv:after {
      display: contents;
    }

    .third_block_serv {
      display: contents;
    }

    .third_block_serv:after {
      display: contents;
    }

    .second_block_serv {
      display: contents;
    }

    .second_block_serv:after {
      display: contents;
    }

    .second_point {
      display: contents;
    }

    .second_point:before {
      display: contents;
    }

  }
  .empty_text_block, .empty_text_block-two {
    display: none;
  }
  .pagination-bott {
    min-width: 320px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .mobil-img-serv {
    .new-list {
      margin-right: inherit !important;
    }

    display: inherit;
  }
  .casses {
    .container-title h1 {
      &:before {
        content: '';
        position: absolute;
        left: -45px !important;
        top: 26px !important;
        width: 28px !important;
        height: 26px !important;
        transform: translateY(-50%) translateX(-50%);
        background: url("/images/roadmap/pol.png");
      }

      &:after {
        content: '';
        background-color: rgba(7, 186, 217, 0.3);
        position: absolute;
        width: 200px !important;
        height: 1px !important;
        left: -220px !important;
        top: 18px !important;
      }
    }
  }

  .keys {
    margin-top: 0 !important;

    .sixth_point_cases .div-el .el {
      .img-el {
        margin-bottom: 18px;
      }
      .text {
        margin-left: 0;
      }
    }
    .banner-link {
      margin-top: 10px;
      margin-bottom: 60px;
      width: 100%;
      height: 60px;
      border: none;
      background: linear-gradient(90deg, #1D8D60 0%, #3CB03F 100%);


      font-weight: 600;

      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      color: #FFFFFF;

      img {
        margin-left: 30px;
        height: 60%;
      }
    }

    .mobil-case-img {

      margin: auto;
    }

    .sixth_point_cases .div-el .el {
      .img-el {
        align-self: start;
      }
    }

    .container-title h1 {
      width: auto !important;
      margin-left: 30px;
      position: relative;

      &:before {
        left: 0 !important;
        top: 0 !important;
        transform: translateX(-30px) translateY(5px) !important;
      }
    }

    .special {
      padding: 0!important;
      width: 100%;
      .main_text_block {
        padding: 0 15px;
      }
      .mobil-case-img {
        max-width: none;
      }
      .container-title {
        h1 {
          position: relative;
        }
      }
    }

    .case-cards {
      margin:0;
      width: 100%;

      .case-card_block {
        img {
          width: 100%;
          max-width: 180px;
        }
        p {
          font-size: 16px;
        }
      }
    }
    .ml-20 {
      margin-left: 20px;
    }
    .li-before {
      margin-left: 20px;

      &:after {
        left: -28px !important;
      }
    }

    .keys-title {
      display: none;
    }

    .region {
      .region-block {
        padding: 0;

        .container-title {
          h1 {
            margin-left: 0;
            text-align: left;

            &:before {
              content: none;
            }

            &:after {
              content: none;
            }
          }
        }
      }

      .anchor {
        display: none;
      }
    }

    .map {
      display: none;
    }

    .first_block_cases {
      margin-bottom: 30px;
      border: none;
      padding: 0 15px;
    }

    .third_block_cases {
      margin-bottom: 50px;
      .mobil-case-img {
        margin-bottom: 37px;
        margin-top: 36px;
      }
      border: none;
      padding: 0;
    }

    .fourth_point_cases {
      border: none;
      padding: 0;
    }

    .fifth_point_cases {
      margin-top: 50px;
      border: none;
    }

    .second_point_cases {
      border: none;
      .image_block, .text_block {
        padding: 0 15px;
      }
      .mobil-case-img {
        margin-bottom: 20px;
        margin-top: 35px;
      }
      .empty_text_block {
        display: none;
      }
    }

    .sixth_point_cases {
      &:after {
        content: none;
      }

      &:before {
        content: none;
      }

      margin-top: 20px;
      border: none;
      padding: 0 15px;

      .div-el .el {
        margin-bottom: 30px;
        height: auto;
      }
    }

    img {
      width: fit-content;
      transform: none !important;
    }
  }

  .contact_bg {
    .contact_img {
      height: 361px;
      margin-bottom: 0;
    }
  }
  .header {
    .header_small {
      padding: 35px 25px;
      height: 80px;

      .nav-item {
        img {
          width: 213px;
        }
      }

      .lang {
        width: 70px;

        img {
          width: 30px;
        }

        a img {
          width: 20px;
        }
      }

      .nav-item ~ img {
        width: 50px;
      }
    }
  }
  .news-pos {
    height: auto;
    margin: 0;
    width: 100vw;

    .rec-item-wrapper {
      height: 100%;
    }

    .news {
      height: 100%;
      display: flex;
      justify-content: center;
      width: 100vw;


      min-height: 120px;
      padding: 33px 25px;
      font-size: 20px;
      line-height: 25px;
    }

    .news:before {
      content: none;
    }

    .news-dots {
      display: none;
    }
  }
  .banner-item-small {
    width: 770px;
    padding: 35px 42px;

    .container-title {
      h3 {
        font-size: 27px;
        line-height: 35px;
      }
    }

    p {
      font-size: 16px;
      line-height: 26px;
    }

    .banner-link {
      width: 700px;
      padding: 25px 0;
      height: 90px;
      background: rgba(8, 28, 58, 0.8);
      border: 2px solid #FFFFFF;
      box-sizing: border-box;
      font-size: 25px;
      line-height: 35px;
    }
  }
  .about {
    margin-bottom: 0!important;
    .about_small {
      .about-text {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

  .WhereuseCarusel {
    width: 720px !important;
    margin: auto !important;
    margin-bottom: 50px !important;

    .element {
      width: 320px;
      height: 235px;
    }
  }

  .services {
    .btn-onservices {
      width: 100%;
      .btn-color {
        height: auto;
        margin: auto;
        width: 90%;

        img {
          display: none;
        }
      }
    }
  }

  .media-element {
    .media-element-logo {
      min-height: 50px;
      align-self: center;
      justify-self: center;
    }
  }
  .roadsmall_title {
    .title-road {
      .container-title {
        position: relative;
        width: max-content;
        margin-top: 90px;
        h1 {
          font-size: 40px;
          line-height: 52px;
          width: max-content;
          position: relative;
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .navbar-nav {
    a {
      font-size: 23px;
      line-height: 35px;
    }
  }
  .media-element-logo {
    height: 100%;
  }
  .contact_bg {
    font-size: 23px;
    a {
      font-size: 23px;
      line-height: 35px;
    }
    .contacts li .contacts_li_img {
      height: 45px;
      width: 100px;
      img {
        width: 45px;
        max-height: 45px
      }
    }

    .input_contacts {
      height: 75px!important;
      font-size: 23px!important;
    }
    textarea.input_contacts {
      height: 145px!important;
    }
    .btn-color_second {
      height: 90px;
      font-size: 25px;

      img {
        width: 35px;
      }
    }
    .privacy {
      font-size: 23px;
    }

  }
  .serv-bg {
    font-size: 23px;
    line-height: 35px;
    .li-before {
      margin-left: 50px;
      & + .ml-20 {
        margin-left: 50px;
      }
      &:after {
        left: -50px!important;
        top: 5px;
      }
    }
    .banner-link {
      height: 90px;

      img {
        height: 40px;
      }
    }
    .serv-bg_title-text,p {
      font-size: 23px;
      line-height: 35px;
    }
    .container-title h1 {
      font-size: 33px;
    }
    .region-block .container-title {
      margin-top: 50px!important;
      width: max-content;
    }
    .keys .case-cards {
      height: 600px;
      margin-bottom: 30px;
      .img_block {
        height: 200px;
      }
      p {
        font-size: 23px;
      }
    }

    .text {
      .title {
        font-size: 25px;
        font-weight: 700!important;
      }
      p {
        font-size: 23px!important;
      }
    }
  }
  .roadmap_bg {
    .roadmap_small {
      .road_title {
        h1 {
          font-size: 40px;
          font-weight: 700;
        }
      }
      .container-title {
        h1 {
          line-height: 52px;
          font-size: 40px!important;
          font-weight: 700;
          max-width: 100%;
          width: max-content;
        }
      }
    }
    .roadmap_svg {
      width: 180px;
      height: 80px;
      margin-top: 30px;
      margin-bottom: 90px;
    }
    .roadmap_svg + p {
      margin-top: 30px;
      margin-left: 60px!important;
      margin-bottom: 90px!important;
    }
    p {
      font-size: 23px;
      line-height: 35px;
      margin-bottom: 23px;
    }
    .banner-link {
      margin-top: 50px;
      font-size: 23px;
      line-height: 35px;
      height: 90px;
    }
    .roadmap_small_btn {
      button {
        width: 80px;
        height: 70px;
        font-size: 23px;
        line-height: 35px;
      }
    }
  }
  footer {
    a {
      font-size: 23px;
      line-height: 35px;
    }
    a img {
      width: 30px;
    }
    a .fa {
      font-size: 40px;
    }
    font-size: 23px;
    line-height: 35px;
  }
  .main-bg {
    font-size: 23px;
    line-height: 35px;
    .container-title h1 {
      font-size: 40px;
      line-height: 52px;
      max-width: 100%;
      width: max-content;
    }
    .TexnologyCarusel {
      height: 500px;
      .about-arrow {
        top: 35%;
      }
      .texnologyCarusel_text_small {
        font-weight: normal;
        font-size: 23px;
        line-height: 35px;
        .mt-1 {
          display: none;
        }
      }
    }
    .WhereuseCarusel {
      .pagination {
        display: none;
      }
      .element p {
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
      }
    }
    p {
      font-size: 23px;
      line-height: 35px;
    }
    .services .about-services_small div {
      font-size: 23px;
      line-height: 35px;
    }
    .cur-img {
      height: 80px;
      width: 70px;
      max-width: 80px;
      display: flex;
    }
    .btn-onservices {
      height: 90px;
      a {
        font-size: 23px;
      }
    }
    .text_block {
      font-size: 23px!important;
      line-height: 35px!important;
    }
  }
  .third_block_serv .min-img .d-flex {
    flex-direction:inherit!important;
    }
  .serv-block .request{
    margin-left: inherit;
  }
  .WhereuseCarusel .about-arrow {
    &.back {
      left: -15px;
      top: 50%;
    }
    &.next {
      right: -15px;
      top: 50%;
    }
  }

  .region-md {
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100vw;
  }
  .keys .region-md-none {
    background: none;
    height: 200px;
  }
  .big-img {
    display: none;
  }
  .imgServDetailed {
    display: flex;
    justify-content: center;

    img {
      width: 70%;
    }
  }
  .ServDetailed-slider {
    order: 1;
  }
  .imgServDetailed-margin {
    .min-img {
      order: 1;
    }

    p {
      margin-bottom: 45px;
    }
  }
  .serv-block .request p {
    //font-size: 15px;
  }
}

@media (max-width: 768px) {
  .result-popup.modal .modal-dialog {
    max-height: 400px;
    .modal-content {
      padding: 33px 33px;
    }
    .btn-color_second img {
      display: none;
    }
  }
    //.TexnologyCarusel .about-arrow.back, .TexnologyCarusel .about-arrow.next{
  //  //  -webkit-transform:inherit;
  //  //  transform:inherit;
  //  //}
  .min-img-servDetailed{
    padding-bottom: 15px;
  }
  .vector-det{
    margin-bottom: 15px;
  }
  .imgServDetailed-result-block{
    p{
      margin-left: 15px;
    }
  }
  .breadcrumbs{
    display: none;
  }
  .logo div {
    &:nth-child(2n) {
      padding-left: 5px;
    }
    &:nth-child(2n+1) {
      padding-right: 5px;
    }
    .white-box {
      width: 100%;
      height: 100px;
      padding: 0;
      margin-bottom: 10px;
    }
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .TexnologyCarusel {
    height: 300px !important;

    .texnologyCarusel_img_small, {
      transform: translateY(-70px);
    }

    .texnologyCarusel_text_small {
      transform: translateY(-140px);
    }

    .about-arrow {
      &.next {
        //transform: translateY(-260%);
      }

      &.back {
        //transform: translateY(-260%);
      }
    }
  }
  .keys-green-block {
    margin-top: 20px;
    margin-left: -30px;
    margin-right: -30px;
    padding: 23px 30px 23px 30px;
  }
  .keys {
    padding: 0 30px;
    .second_point_cases  {
      .mobil-case-img {
        width: 100%;
        max-width: none;
      }
    }
    .case-cards {
      margin:0;
      width: 100%;

      .case-card_block {
        img {
          width: 100%;
          max-width: 140px;
        }
      }
    }
    .special .mobil-case-img {
      width: 100%;
    }
    .mobil-case-img {
      max-width: 230px;
    }
  }
  .keys .region {
    height: auto;
    background: none;
  }
  .mobil-img {
    display: inherit;
    //margin-left: 15px;

    .min-img-servDetailed {
      margin-bottom: 10px;
    }
  }
  .ServDetailed-slider {
    display: none;
  }
  .contact_bg {

    .privacy {
      margin-bottom: 50px;
    }
  }
  .contact_small {
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    /* or 140% */
  }
  .serv-block .btn {
    border-radius: inherit;
    border: none;
    height: 60px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .min-img-block {
    .min-img {
      width: 100%;
    }
  }
  .element {
    margin-top: 40px;
  }
  .vector-on {
    display: block;
    background-image: url("/images/servicesPage/vactor_pag.png");
    background-repeat: no-repeat;
    height: 40px;
    width: 20px;
  }
  .imgServDetailed {
    display: flex;
    align-content: stretch;
    justify-content: space-evenly;
    img {
      //width: 100%;
    }
  }
  .case-region-сarusel .case-region {
    font-size: 16px;
    line-height: 26px;
  }
  .header {
    z-index: 100;

    .header_small {
      padding: 25px 25px;
      height: 46px;

      .nav-item {
        img {
          width: 130px;
        }
      }

      .lang {
        width: 40px;

        img {
          width: 20px;
        }

        a img {
          width: 13px;
        }
      }

      .nav-item ~ img {
        width: 30px;
      }
    }
  }
  .news-pos {
    .news {
      min-height: 80px;
      padding: 10px 25px;
      font-size: 15px;
      line-height: 22px;
    }
  }
  .banner-item-small {
    width: 100%;
    padding: 16px 15px;

    .container-title {
      h3 {
        font-size: 27px;
        line-height: 35px;
        margin-bottom: 10px;
      }
      img {
        margin-bottom: 10px;
      }
    }

    p {
      font-size: 16px;
      line-height: 26px;
    }

    .banner-link {
      margin-top: 16px;
      width: 100%;
      height: 60px;
      border: none;
      background: linear-gradient(90deg, #1D8D60 0%, #3CB03F 100%);

      font-weight: 600;

      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      color: #FFFFFF;
    }
  }
  .about {
    .about_small {
      .about-text {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  .WhereuseCarusel {
    width: auto !important;

    .element {
      width: 100%;
    }
  }
  .slider-1_block_small {
    .about-item {
      width: 100%;
      height: 245px;
    }
    .about-item-title {
      font-size: 19px;
      line-height: 28px;
      height: 200px;
      top: 50px;
      width: 80%;
    }
    .about-arrow {
      top: 40%;
      &.next {
        right: 0px;
      }
      &.back {
        left: 0px;
      }
    }
  }
  .media-element {
    min-height: 530px;

    .media-element-logo {
      min-height: 50px;
    }
  }
  .roadsmall_title {
    .title-road .container-title {
      h1 {
        font-size: 27px;
        line-height: 35px;
      }
    }
  }
}

@media (max-width: 768px) {
  .pagination-top {

    .page-items {
      margin-left: 22px;
      .page-link {
        margin-right: 10px;
      }
    }
  }
  .servis{
    margin-left: 22px;
  }
  .page-items {}
  .media-small {
    h1 {
      margin-bottom: 50px;
    }
    .media-element-logo {
      margin-bottom: 50px;
    }
    h4 {
      margin-bottom: 20px;
    }
    .smi-btnon {
      margin-top: 20px;
      margin-bottom: 0;
    }
    .inline_arrow {
      position: absolute;
      bottom: -25px;
    }
  }
  .serv-block {
    #new-list {
      display: block;
    }

    .img {
      height: inherit;
      margin-right: inherit;
      background-position-y: 50%;
    }

    .align-self-left {
      margin-left: inherit;
    }

    ul.hr {
      margin-top: inherit;
    }

    .img-c {
      margin-right: inherit;
      margin-left: inherit;
    }

    .text-next {
      display: none;

      img {
        margin-top: inherit;
        margin-left: 7px;
      }
    }

    .min-img-block img {
      height: inherit;
      width: inherit;
      margin: initial;
    }

    .text {
      margin-left: 20px;
      margin-right: 20px
    }

    .request {
      margin-left: 25px;
    }

    .bottom {
      margin-right: 30px;
      margin-left: inherit;
    }

    .img-c {
      height: inherit;
    }

  }
  .page-link {
    font-size: 15px;
    text-align: center;
  }
  .vector-off {
    display: none;
  }
  .page-link.active {
    background: linear-gradient(90deg, #0D7A53, #21A220);
  }
  .page-link.active span#color {
    font-size: 15px;
    background: rgb(255, 255, 255);
    -webkit-background-clip: text;
  }
  .serv-block .btn {
    border-radius: inherit;
    border: none;
    height: 60px;
    margin-left: 5%;
    margin-right: 5%;
  }


}

@media (max-width: 500px) {
  .text-Serv-Detailed{
    .container-title h1{
      //font-size: 20px;
    }
  }
  .contact_bg .contacts li .contacts_li_img {
    width: 25px;
    height: 25px;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .contact_bg .contact_img {
    height: 227px;
    margin-bottom: 0;
  }
  .contact-small {
    .fa {
      font-size: 30px;
    }
  }
}

@media (max-width: 320px) {
  .serv-block .request p {
    //font-size: 12px;
  }
  .serv-block .request{
    margin-left: 15px;
  }
  .page-link {
    //font-size: 10px;
    text-align: center;

  }
  .page-link.active span#color {
    font-size: 10px;
    background: rgb(255, 255, 255);
    -webkit-background-clip: text;
  }
}

